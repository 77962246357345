@use 'node_modules/@ngneat/hot-toast/src/styles/styles.scss';

.hot-toast-bar-base {
  @apply text-base font-medium rounded-md;

  .hot-toast-close-btn {
    @apply transition-all duration-75 ease-in;
  }

  &.custom-toast-error {
    @apply bg-feedback-warning-light/75 text-feedback-warning-medium #{!important};

    .hot-toast-close-btn {
      @apply shadow-sm focus:shadow-feedback-warning-medium #{!important};
    }
  }

  &.custom-toast-info {
    @apply bg-brand-sextenary-lighter/75 text-brand-sextenary-dark #{!important};

    .hot-toast-close-btn {
      @apply shadow-sm focus:text-brand-sextenary-light #{!important};
    }
  }

  &.custom-toast-success {
    @apply bg-feedback-success-light/75 text-feedback-success-medium #{!important};

    .hot-toast-close-btn {
      @apply shadow-sm focus:shadow-feedback-success-medium #{!important};
    }
  }

  &.custom-toast-warning {
    @apply bg-feedback-helper-light/75 text-feedback-helper-medium #{!important};

    .hot-toast-close-btn {
      @apply shadow-sm focus:text-feedback-helper-medium #{!important};
    }
  }
}
