/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'styles/index';

@layer utilities {
  .action-round-button {
    @apply rounded-3xl shadow-md border border-black bg-white w-12 h-12 p-2 flex items-center justify-center transition-opacity duration-150 ease-in-out hover:bg-neutral-hight-pure-light;
  }
}
